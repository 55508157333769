<template>
  <el-form :model="contractForm" :rules="rules" ref="form" label-width="130px" class="contractForm" size="small">
    <el-row>
      <el-col :span="24">
        <el-row>
          <el-col :span="12">
            <el-form-item label="合同编号" prop="contract_code">
              <el-input v-model="contractForm.contract_code"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="对方公司名称" prop="yifang">
              <el-input v-model="contractForm.yifang"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="施工地点" prop="projectPlace">
              <el-input v-model="contractForm.projectPlace"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人" prop="lianxiren">
              <el-input v-model="contractForm.lianxiren"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="contact_phone">
              <el-input v-model="contractForm.contact_phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="我方公司名称" prop="jiafang">
              <el-select v-model="contractForm.jiafang" placeholder="请选择我方公司名称" style="width:300px">
                <el-option v-for="item in first_party_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="回款负责人" prop="fuzeren">
              <el-input v-model="contractForm.fuzeren"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="欠款金额" prop="qiankuan_amount">
          <el-input type="number" step="0.01" v-model="contractForm.qiankuan_amount"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="未返还材料情况" prop="notreturnmat">
          <el-input type="textarea" :rows="4" v-model="contractForm.notreturnmat"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item>
      <div style="float:right;">
        <el-button @click="resetForm()"> 取消 </el-button>
        <el-button type="primary" @click="submitForm()"> 确认修改 </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    contractForm: Object
  },
  data() {
    return {
      loading: false,
      first_party_options: [],
      rules: {
        contract_code: [{ required: true, message: '请输入合同编号', trigger: 'blur' }],
        jiafang: [{ required: true, message: '请选择我方公司名称', trigger: 'blur' }],
        yifang: [{ required: true, message: '请选择对方公司名称', trigger: 'blur' }],
        projectPlace: [{ required: true, message: '请输入施工地点', trigger: 'blur' }],
        lianxiren: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        fuzeren: [{ required: true, message: '请输入负责人', trigger: 'blur' }],
        qiankuan_amount: [{ required: true, message: '请填写正确的金额,保留两位小数', pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/ }],
        notreturnmat: [{ required: true, message: '请填写施工材料', trigger: 'blur' }]
      }
    }
  },
  methods: {
    getFirstPartyList() {
      this.$axios.get('/getFirstPartyList/').then(res => {
        console.log('users', res.data)
        res.data.forEach(element => {
          let first_party = {}
          first_party.label = element.name
          first_party.value = element.name
          this.first_party_options.push(first_party)
        })
        console.log('first_party_options', this.first_party_options)
      })
    },

    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.contractForm.ContractType = this.ContractType
          this.$emit('submitForm', this.contractForm)
          this.contractForm = {}
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs['form'].resetFields()
      this.$emit('resetForm')
    }
  },
  created() {
    this.getFirstPartyList()
  }
}
</script>

<style lang="scss" scoped></style>
