<template>
  <div class="manage">
    <el-dialog width="900px" title="新增合同" :visible.sync="isShow" :close-on-click-modal="false">
      <contract-form ref="contractForm" @submitForm="submitForm" @resetForm="resetForm"></contract-form>
    </el-dialog>
    <el-dialog width="900px" title="修改合同" :visible.sync="isEditShow" :close-on-click-modal="false">
      <ContractEditForm :contractForm="contractForm" @submitForm="submitEditForm" @resetForm="resetEditForm"></ContractEditForm>
    </el-dialog>

    <div class="manage-header">
      <el-button type="primary" :disabled="authority !== '5'" @click="addContract">+ 新增</el-button>

      <div class="searchdiv">
        <input
          class="searchinput"
          type="text"
          v-model="keyword"
          placeholder="合同编号 | 对方公司名称 | 联系人 | 联系电话 | 我方负责人"
          @keyup.enter="getKeyword"
        />
        <button type="button" class="searchbutton" @click="getKeyword">搜索</button>
      </div>
    </div>

    <common-table
      :tableData="tableData"
      :config="config"
      :usertype="usertype"
      @edit="editContract"
      @getSelectedRows="getSelectedRows"
      @changePage="getList"
      @del="delContract"
    >
    </common-table>
  </div>
</template>

<script>
// import CommonForm from '../../components/CommonForm'
import CommonTable from './HtFawuContractTable'
import ContractForm from './HtFawuContractForm'
import ContractEditForm from './HtFawuContractEditForm'

import utils from '../../assets/js/utils'

// import Cookie from 'js-cookie'

export default {
  components: {
    // CommonForm,
    CommonTable,
    ContractForm,
    ContractEditForm
  },
  data() {
    return {
      isShow: false,
      isOldShow: false,
      isEditShow: false,
      tableData: [],
      usertype: '',
      authority: '',
      user_realname: '',

      keyword: '',

      config: {
        page: 1,
        total: 30,
        loading: false
      },

      contractForm: {},
      oldContractForm: {}
    }
  },
  watch: {
    'config.page': function() {
      utils.setPageCookie(this.config.page)
    }
  },

  methods: {
    // 获取合同列表
    getList() {
      //获取当前用户信息：姓名，用户类型
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.authority = user.authority
      this.user_realname = user.name
      //判断是否是从详情页返回的
      let from = this.$route.query.from
      if (from === 'detail') {
        this.keyword = utils.getKeywordCookie()
        this.config.page = utils.getPageCookie()
        // alert(this.config.page)
        this.$route.query.from = ''
        console.log('this.keyword', this.keyword)
      }
      console.log('user:', user)
      console.log('usertype:', this.usertype)
      console.log('user_realname:', this.user_realname)
      this.config.loading = true
      const api = '/getFawuContracts/'
      this.$axios
        .get(api, {
          params: {
            page: this.config.page,
            keyword: this.keyword
          }
        })
        .then(res => {
          console.log('res.data', res.data)
          this.config.total = res.data.totalcount
          console.log('total:', this.config.total)
          this.tableData = res.data.data
          this.tableData = res.data.data.map(item => {
            item.added_person_time = utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
            return item
          })
          this.config.loading = false
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //获取搜索关键词
    getKeyword() {
      // alert(this.keyword)
      utils.setKeywordCookie(this.keyword)
      this.config.page = 1
      console.log('keyword_cookie', this.keyword)
      this.getList()
    },
    //增加合同
    addContract() {
      this.contractForm = {}
      this.operateType = 'add'
      this.isShow = true
      // this.$router.push({ name: 'addcontract' })
    },

    //编辑合同
    editContract(row) {
      console.log('row........', row)

      this.isEditShow = true
      // row.contract_amount = parseInt(row.contract_amount)
      // row.real_amount = parseInt(row.real_amount)
      // row.signing_date = new Date(row.signing_date)
      this.contractForm = row
      console.log('The new row:', this.contractForm)
    },

    //删除合同
    delContract(row) {
      this.$confirm('此操作将永久删除该合同, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .get('/deleteFawuContract/', {
              params: {
                id: row.id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getList()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    getSelectedRows(val) {
      console.log('Selected rows:', val)
    },
    submitForm(formData) {
      this.contractForm = formData
      console.log('contractForm........:', this.contractForm)

      //增加合同
      console.log('add contract:', this.contractForm)
      this.contractForm.added_person = this.user_realname
      this.contractForm.added_person_time = utils.getDateTime()

      console.log('add contract2222:', this.contractForm)
      this.$axios.post('/addFawuContract/', this.contractForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === 1000) {
          this.$message({
            type: 'success',
            message: '增加合同成功!',
            duration: 2000
          })
          this.isShow = false
          this.getList()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetForm() {
      this.isShow = false
      this.contractForm = {}
    },
    submitEditForm(formData) {
      this.contractForm = formData
      console.log('contractForm........:', this.contractForm)

      //修改合同
      console.log('add contract:', this.contractForm)
      this.contractForm.added_person = this.user_realname
      this.contractForm.added_person_time = utils.getDateTime()

      console.log('add contract2222:', this.contractForm)
      this.$axios.put('/editFawuContract/', this.contractForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === 1000) {
          this.$message({
            type: 'success',
            message: '修改合同成功!',
            duration: 2000
          })
          this.isEditShow = false
          this.getList()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetEditForm() {
      this.isEditShow = false
      this.contractForm = {}
    }
  },
  created() {
    this.config.loading = true
    this.getList()
  }
}
</script>
<style scoped>
.searchdiv {
  height: 40px;
  line-height: 40px;
}
.searchinput {
  width: 350px;
  height: 35px;
  text-align: center;
}
.searchbutton {
  width: 80px;
  height: 40px;
  background-color: #409eff;
}
</style>
